*, *::after, *::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}
@media (max-width: 750px), (max-height: 700px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: Roboto, sans-serif;
}
body #root {
  display: flex;
  justify-content: center;
}

.App {
  position: relative;
  width: 100%;
  min-width: 600px;
  max-width: 750px;
}
.App .cards {
  display: flex;
}
.App .cards > .card {
  width: 50%;
}
.App .button-bar {
  margin-top: 1.5rem;
  display: flex;
}
.App .button-bar > button {
  margin-right: 1.5rem;
}

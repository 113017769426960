/* -----------------------------------------------------
  Button derived from Material Design Buttons by Ravikumar Chauhan
  https://codepen.io/rkchauhan/pen/NNKgJY
  GitHub: https://github.com/rkchauhan
-------------------------------------------------------- */
button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  padding: 0 1.5rem;
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.8px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  border: none;
  user-select: none;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
}
@media (max-width: 750px), (max-height: 700px) {
  button {
    font-size: 14px;
  }
}
button:hover {
  text-decoration: none;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
}
button.inverse {
  color: #fff;
  background-color: #6D917C;
}
button.inverse:hover {
  background-color: rgb(126.3267716535, 158.2732283465, 139.6377952756);
}
button.disabled, button[disabled] {
  cursor: default !important;
  color: #9e9e9e !important;
  box-shadow: none !important;
}
button.disabled:not(.flat), button[disabled]:not(.flat) {
  background-color: #cdcdcd !important;
}
button.disabled:not(.flat):hover, button[disabled]:not(.flat):hover {
  background-color: #cdcdcd !important;
}
button.flat {
  box-shadow: none !important;
  background-color: transparent !important;
}
button.flat:hover {
  background-color: #cecece !important;
  box-shadow: none !important;
}
button.flat.disabled:hover, button.flat[disabled]:hover {
  background-color: transparent !important;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-overlay .content {
  z-index: 2;
}
.modal-overlay .click-bait {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.card {
  flex-direction: column;
  display: flex;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  flex-grow: 1;
  margin-right: 1rem;
}
.card .title {
  background-color: #BBDCC7;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  align-items: center;
}
@media (max-width: 750px), (max-height: 700px) {
  .card .title {
    font-size: 16px;
  }
}
.card .title > div {
  padding: 1rem;
}
.card .title .name {
  flex-grow: 1;
}
.card .title .action {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  border-radius: 9999px;
  margin-right: 0.5rem;
  transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card .title .action:hover {
  background-color: rgb(207.2019417476, 230.3980582524, 215.6368932039);
}
.card .title .action img {
  position: absolute;
}
.card .body {
  flex-grow: 1;
}

/**
  * Derived from
  * https://scotch.io/tutorials/css/google-material-design-input-boxes-in-css3
  */
.text-input {
  margin-bottom: 32px;
}
.text-input .inner {
  position: relative;
  margin-top: 32px;
  /* active state */
  /* active state */
  /* HIGHLIGHTER ================================== */
  /* active state */
}
.text-input .inner input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
}
@media (max-width: 750px), (max-height: 700px) {
  .text-input .inner input {
    font-size: 16px;
  }
}
.text-input .inner textarea {
  font-size: 14px;
  padding: 10px 10px 10px 5px;
  border: none;
  border-bottom: 1px solid #757575;
  white-space: nowrap;
  overflow-wrap: normal;
  overflow: scroll;
}
@media (max-width: 750px), (max-height: 700px) {
  .text-input .inner textarea {
    font-size: 12px;
  }
}
.text-input .inner input:focus,
.text-input .inner textarea:focus {
  outline: none;
}
.text-input .inner label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
@media (max-width: 750px), (max-height: 700px) {
  .text-input .inner label {
    font-size: 16px;
  }
}
.text-input .inner input:focus ~ label,
.text-input .inner input:valid ~ label,
.text-input .inner textarea:focus ~ label,
.text-input .inner textarea:valid ~ label {
  top: -20px;
  left: -1px;
  font-size: 14px;
  color: #5264ae;
}
@media (max-width: 750px), (max-height: 700px) {
  .text-input .inner input:focus ~ label,
  .text-input .inner input:valid ~ label,
  .text-input .inner textarea:focus ~ label,
  .text-input .inner textarea:valid ~ label {
    font-size: 12px;
  }
}
.text-input .inner .bar {
  position: relative;
  display: block;
  width: 300px;
}
.text-input .inner .bar:before, .text-input .inner .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.text-input .inner .bar:before {
  left: 50%;
}
.text-input .inner .bar:after {
  right: 50%;
}
.text-input .inner input:focus ~ .bar:before,
.text-input .inner input:focus ~ .bar:after,
.text-input .inner textarea:focus ~ .bar:before,
.text-input .inner textarea:focus ~ .bar:after {
  width: 50%;
}
.text-input .inner .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.text-input .inner input:focus ~ .highlight,
.text-input .inner textarea:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
.text-input .helper {
  margin-top: 8px;
  font-size: 14px;
  color: #999;
}
@media (max-width: 750px), (max-height: 700px) {
  .text-input .helper {
    font-size: 12px;
  }
}

.checkbox {
  margin-bottom: 32px;
}
.checkbox .inner {
  position: relative;
  margin-top: 32px;
  /* active state */
  /* active state */
  /* HIGHLIGHTER ================================== */
  /* active state */
}
.checkbox .inner input {
  font-size: 18px;
  border: none;
}
@media (max-width: 750px), (max-height: 700px) {
  .checkbox .inner input {
    font-size: 16px;
  }
}
.checkbox .inner input:focus {
  outline: none;
}
.checkbox .inner label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  pointer-events: none;
}
@media (max-width: 750px), (max-height: 700px) {
  .checkbox .inner label {
    font-size: 16px;
  }
}
.checkbox .inner input:focus ~ label {
  color: #5264ae;
}
.checkbox .inner input:focus ~ .bar:before,
.checkbox .inner input:focus ~ .bar:after {
  width: 50%;
}
.checkbox .inner .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.checkbox .inner input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
.checkbox .helper {
  margin-top: 8px;
  font-size: 14px;
  color: #999;
}
@media (max-width: 750px), (max-height: 700px) {
  .checkbox .helper {
    font-size: 12px;
  }
}

.dialog {
  background-color: #fff;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
}
.dialog .body {
  padding: 1rem;
  color: #58585A;
}
.dialog .body .button-bar {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.scan-barcode-button {
  display: flex;
}
.scan-barcode-button .button-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}
.scan-barcode-button .button-container button {
  margin-left: 0.5rem;
  margin-top: 32px;
  height: 3rem;
  width: 3rem;
  padding: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
.scan-barcode-button .button-container button img {
  width: 2rem;
  height: 2rem;
}

.barcode-scanner {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.barcode-scanner .video-container {
  position: fixed;
  top: 20%;
  left: 20%;
  right: 20%;
  min-width: 480px;
  min-height: 320px;
  background-color: #a0a0a0;
  display: flex;
  align-items: center;
}
.barcode-scanner .video-container canvas {
  left: 100vw;
  position: fixed;
}
.barcode-scanner .video-container .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 3;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.barcode-scanner .video-container .close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.barcode-scanner .video-container .close img {
  width: 2rem;
  height: 2rem;
}
.barcode-scanner .video-container .label-container {
  position: absolute;
  bottom: 0.75rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.barcode-scanner .video-container .label-container .label {
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px 8px;
  font-size: 12px;
}
@media (max-width: 750px), (max-height: 700px) {
  .barcode-scanner .video-container .label-container .label {
    font-size: 12px;
  }
}
.barcode-scanner .video-container video {
  width: 100%;
}
